import './MaskModal.css';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Layer, Line, Stage } from 'react-konva';
import Modal from 'react-modal';

const MaskModal = ({ imgSrc, isOpen, onClose, onConfirm, title }) => {
    const [height, setHeight] = useState(512);
    const isDrawing = useRef(false);
    const [lines, setLines] = useState([]);
    const stageRef = useRef(null);
    const { t } = useTranslation();
    const [tool, setTool] = useState('pen');
    const [width, setWidth] = useState(512);
    const [windowImg, setWindowImg] = useState(null);

    const getCanvasURI = () => {
        const offScreenCanvas = document.createElement('canvas');
        offScreenCanvas.height = windowImg.height;
        offScreenCanvas.width = windowImg.width;
        const ctx = offScreenCanvas.getContext('2d');

        ctx.fillStyle = 'black';
        ctx.fillRect(0, 0, windowImg.width, windowImg.height);

        ctx.lineWidth = 50;
        ctx.lineCap = 'round';
        ctx.lineJoin = 'round';
        lines.forEach((line) => {
            ctx.strokeStyle = line.tool === 'eraser' ? 'black' : 'white';
            ctx.beginPath();
            ctx.moveTo(line.points[0], line.points[1]);
            for (let i = 2; i < line.points.length; i += 2) {
                ctx.lineTo(line.points[i], line.points[i + 1]);
            }
            ctx.stroke();
        });

        return offScreenCanvas.toDataURL();
    };

    const handleMouseDown = (e) => {
        isDrawing.current = true;
        const pos = e.target.getStage().getPointerPosition();
        setLines([...lines, { tool, points: [pos.x, pos.y] }]);
    };

    const handleMouseMove = (e) => {
        if (!isDrawing.current) {
            return;
        }
        const stage = e.target.getStage();
        const point = stage.getPointerPosition();
        let lastLine = lines[lines.length - 1];
        lastLine.points = lastLine.points.concat([point.x, point.y]);
        lines.splice(lines.length - 1, 1, lastLine);
        setLines(lines.concat());
    };

    const handleMouseUp = () => {
        isDrawing.current = false;
    };

    const reset = () => {
        setLines([]);
        setTool('pen');
    };

    useEffect(() => {
        function getImage(src) {
            return new Promise(function (resolve, reject) {
                const img = new window.Image();
                img.src = src;
                img.onload = function () {
                    resolve(img);
                };
                img.crossOrigin = 'Anonymous';
                img.error = function (e) {
                    reject(e);
                };
            });
        }
        getImage(imgSrc).then((img) => {
            setWindowImg(img);
            setWidth(img.width);
            setHeight(img.height);
        });
    }, [imgSrc]);

    return (
        <Modal
            className="mask-modal"
            onRequestClose={() => {
                onClose();
                reset();
            }}
            isOpen={isOpen}
            contentLabel={`${t('mask.title')} ${title}`}
        >
            <div className="mask-select">
                <select
                    onChange={(e) => setTool(e.target.value)}
                    style={{ width: 120 }}
                    value={tool}
                >
                    <option value="eraser">{t('mask.eraser')}</option>
                    <option selected value="pen">
                        {t('mask.pen')}
                    </option>
                </select>
            </div>
            <div className="flex items-center justify-center">
                <Stage
                    height={height}
                    onMouseDown={handleMouseDown}
                    onMousemove={handleMouseMove}
                    onMouseup={handleMouseUp}
                    ref={stageRef}
                    width={width}
                >
                    <Layer>
                        <Image
                            height={height}
                            image={windowImg}
                            opacity={0.8}
                            width={width}
                        ></Image>
                    </Layer>
                    <Layer>
                        {lines.map((line, i) => (
                            <Line
                                globalCompositeOperation={
                                    line.tool === 'eraser'
                                        ? 'destination-out'
                                        : 'source-over'
                                }
                                key={i}
                                lineCap="round"
                                lineJoin="round"
                                points={line.points}
                                stroke="white"
                                strokeWidth={50}
                                tension={0.5}
                            />
                        ))}
                    </Layer>
                </Stage>
            </div>
            <div className="mask-footer">
                <button type="button" onClick={reset}>
                    {t('mask.reset')}
                </button>
                <button
                    className="bg-blue-800"
                    onClick={() => {
                        onConfirm(getCanvasURI());
                        reset();
                    }}
                    type="button"
                >
                    {t('confirm')}
                </button>
            </div>
        </Modal>
    );
};

MaskModal.propTypes = {
    imgSrc: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.func.isRequired
};

export default MaskModal;
