export const authorize = async (id, email, generations, reauthorize) => {
    const auth = await fetch(`${process.env.REACT_APP_API}/pay/hold`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            mode: 'cors',
            'X-App': `${process.env.REACT_APP_KEY}`
        },
        body: JSON.stringify({
            customer: {
                id: id,
                email: email,
                generations: generations,
                reauthorize: reauthorize
            }
        })
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Authorize: ${response.status}`);
            }

            return response.json();
        })
        .catch((error) => {
            console.error(`${error}`);
        });

    return auth;
};

export const checkout = async (id) => {
    const transaction = await fetch(
        `${process.env.REACT_APP_API}/pay/checkout/${id}`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                mode: 'cors',
                'X-App': `${process.env.REACT_APP_KEY}`
            },
            body: JSON.stringify({})
        }
    )
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Checkout: ${response.status}`);
            }

            return response.json();
        })
        .catch((error) => {
            console.error(`${error}`);
        });

    return transaction.data;
};

export const createSession = async (email) => {
    const session = await fetch(`${process.env.REACT_APP_API}/session/create`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            mode: 'cors',
            'X-App': `${process.env.REACT_APP_KEY}`
        },
        body: JSON.stringify({
            session: { email: email }
        })
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Session[create]: ${response.status}`);
            }

            return response.json();
        })
        .catch((error) => {
            console.error(`${error}`);
        });

    return session;
};

export const getLayers = async (id) => {
    const canvas = await fetch(
        `${process.env.REACT_APP_API}/layer/state/${id}`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                mode: 'cors',
                'X-App': `${process.env.REACT_APP_KEY}`
            }
        }
    )
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Layer[get]: ${response.status}`);
            }

            return response.json();
        })
        .catch((error) => {
            console.error(`${error}`);
        });

    return canvas.layers;
};

export const getSession = async (id) => {
    const session = await fetch(`${process.env.REACT_APP_API}/session/${id}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            mode: 'cors',
            'X-App': `${process.env.REACT_APP_KEY}`
        }
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Session[get]: ${response.status}`);
            }

            return response.json();
        })
        .catch((error) => {
            console.error(`${error}`);
        });

    return session;
};

export const getStyle = async (
    id,
    category,
    accuracy,
    furniture,
    image,
    service,
    style
) => {
    const styled = await fetch(
        `${process.env.REACT_APP_API}/style/${service}/${id}`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                mode: 'cors',
                'X-App': `${process.env.REACT_APP_KEY}`
            },
            body: JSON.stringify({
                image: {
                    accuracy,
                    category,
                    furniture: furniture,
                    style,
                    url: image
                }
            })
        }
    )
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Style[get]: ${response.status}`);
            }

            return response.json();
        })
        .catch((error) => {
            console.error(`${error}`);
        });

    return styled.url;
};

export const layerAdd = async (id, image) => {
    const canvas = await fetch(`${process.env.REACT_APP_API}/layer/add/${id}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            mode: 'cors',
            'X-App': `${process.env.REACT_APP_KEY}`
        },
        body: JSON.stringify({
            image: {
                base64: image
            }
        })
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Layer[add]: ${response.status}`);
            }

            return response.json();
        })
        .catch((error) => {
            console.error(`${error}`);
        });

    return canvas.layer;
};

export const layerDelete = async (id, layerId) => {
    const canvas = await fetch(
        `${process.env.REACT_APP_API}/layer/${id}/${layerId}`,
        {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                mode: 'cors',
                'X-App': `${process.env.REACT_APP_KEY}`
            }
        }
    )
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Layer[delete]: ${response.status}`);
            }

            return response.json();
        })
        .catch((error) => {
            console.error(`${error}`);
        });

    return canvas.layers;
};

export const mask = async (id, image) => {
    const mask = await fetch(`${process.env.REACT_APP_API}/layer/mask/${id}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            mode: 'cors',
            'X-App': `${process.env.REACT_APP_KEY}`
        },
        body: JSON.stringify({
            base64: image
        })
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Layer[mask]: ${response.status}`);
            }

            return response.json();
        })
        .catch((error) => {
            console.error(`${error}`);
        });

    return mask.layer;
};

export const preprocess = async (id, image, mode, service, signal) => {
    const reply = await fetch(
        `${process.env.REACT_APP_API}/style/${service}/preprocess/${id}`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                mode: 'cors',
                'X-App': `${process.env.REACT_APP_KEY}`
            },
            body: JSON.stringify({
                chat: {
                    mode: mode,
                    url: image
                }
            }),
            signal: signal
        }
    )
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Style[preprocess]: ${response.status}`);
            }

            return response.json();
        })
        .catch((error) => {
            if (error.name === 'AbortError') {
                console.log(`Preprocess call canceled`);
            } else {
                console.error(`${error}`);
            }
        });

    return reply;
};

export const resumeSession = async (mail) => {
    const session = await fetch(`${process.env.REACT_APP_API}/session/resume`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            mode: 'cors',
            'X-App': `${process.env.REACT_APP_KEY}`
        },
        body: JSON.stringify({ mail })
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Session[resume]: ${response.status}`);
            }

            return response.json();
        })
        .catch((error) => {
            console.error(`${error}`);
        });

    return session;
};

export const shareSession = async (id, mail) => {
    const session = await fetch(
        `${process.env.REACT_APP_API}/session/export/${id}`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                mode: 'cors',
                'X-App': `${process.env.REACT_APP_KEY}`
            },
            body: JSON.stringify({ mail })
        }
    )
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Export: ${response.status}`);
            }

            return response.json();
        })
        .catch((error) => {
            console.error(`${error}`);
        });

    return session;
};

export const updateSession = async (id, session) => {
    const update = await fetch(`${process.env.REACT_APP_API}/session/${id}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            mode: 'cors',
            'X-App': `${process.env.REACT_APP_KEY}`
        },
        body: JSON.stringify({ update: session })
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Session[update]: ${response.status}`);
            }

            return response.json();
        })
        .catch((error) => {
            console.error(`${error}`);
        });

    return update;
};

export const upscale = async (
    id,
    category,
    accuracy,
    image,
    service,
    style
) => {
    const styled = await fetch(
        `${process.env.REACT_APP_API}/style/${service}/upscale/${id}`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                mode: 'cors',
                'X-App': `${process.env.REACT_APP_KEY}`
            },
            body: JSON.stringify({
                image: {
                    category,
                    accuracy,
                    style,
                    url: image
                }
            })
        }
    )
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Upscale: ${response.status}`);
            }

            return response.json();
        })
        .catch((error) => {
            console.error(`${error}`);
        });

    return styled.url;
};
