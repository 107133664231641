import { getStyle } from '../../api.js';
import i18n from '../../i18n.js';
import { notifications } from '@mantine/notifications';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FaCartShopping,
    FaCircleExclamation,
    FaCirclePlus,
    FaHouseChimney
} from 'react-icons/fa6';
import StyleSelectItem from '../StyleSelectItem/StyleSelectItem.js';
import './StyleSelectModal.css';

Modal.setAppElement('#root');

const StyleSelectModal = ({
    ConfirmationModal,
    confirmationModalOpen,
    genCount,
    handleCheckoutResume,
    handleConfirmCheckout,
    isOpen,
    onClose,
    sessionID,
    setConfirmationModalOpen,
    setGenCount,
    setIsLoading,
    setItems,
    setStyles,
    styles,
    upload,
    uploadRef
}) => {
    const [creativeMode, setCreativeMode] = useState(false);
    const [exteriorMode, setExteriorMode] = useState(false);
    const mode = useMemo(
        () => (exteriorMode ? 'exterior' : 'interior'),
        [exteriorMode]
    );
    const precision = useMemo(
        () => (creativeMode ? 'creative' : 'accurate'),
        [creativeMode]
    );
    const sessionLimit = parseInt(process.env.REACT_APP_AMOUNT);
    const [styleCount, setStyleCount] = useState(0);

    const handleAddStyle = (style) => {
        if (styleCount < 10) {
            const update = { ...styles };

            update[mode][style].count = update[mode][style].count + 1;
            setStyles(update);
        } else {
            notifications.show({
                autoClose: 4000,
                color: 'purple',
                icon: <FaCircleExclamation />,
                loading: false,
                message: i18n.t('toast.maxed', {
                    amount: 10
                }),
                title: i18n.t('toast.max'),
                withCloseButton: false
            });
        }

        setGenCount((prevCount) => prevCount + 1);
    };

    const handleModifierChange = (mod, style, value) => {
        setCreativeMode(true);
        styles[mode][style].modifier[mod] = value;
    };

    const handleRemoveStyle = (style) => {
        const update = { ...styles };

        if (update[mode][style].count > 0) {
            update[mode][style].count = update[mode][style].count - 1;
        }
        setStyles(update);

        setGenCount((prevCount) => prevCount - 1);
    };

    const handleSubmit = () => {
        setIsLoading(true);
        onClose();

        if (genCount >= sessionLimit) {
            handleCheckoutResume();
            setGenCount(genCount - sessionLimit);
        }

        Object.keys(styles[mode]).forEach((style) => {
            const amount = styles[mode][style].count;
            const construction = styles[mode][style]['modifier'].construction
                ? styles[mode][style]['modifier'].construction
                      .replace('_', ' ')
                      .toLowerCase()
                : styles[mode][style]['modifier'].construction;
            const furniture = styles[mode][style]['modifier'].furniture
                ? styles[mode][style]['modifier'].furniture
                      .replace('_', ' ')
                      .toLowerCase()
                : styles[mode][style]['modifier'].furniture;
            const landscape = styles[mode][style]['modifier'].landscape
                ? styles[mode][style]['modifier'].landscape
                      .replace('_', ' ')
                      .toLowerCase()
                : styles[mode][style]['modifier'].landscape;

            for (let i = 0; i < amount; i++) {
                const item = `${style}-${i + 1}`;
                const salt = crypto.getRandomValues(new Uint32Array(1))[0];

                setItems((prevItems) => {
                    return {
                        ...prevItems,
                        [item]: {
                            creative: creativeMode,
                            exterior: exteriorMode,
                            loading: true,
                            modifier: {
                                construction: construction,
                                furniture: furniture,
                                landscape: landscape
                            },
                            image: null,
                            salt: salt
                        }
                    };
                });

                getStyle(
                    sessionID,
                    mode,
                    precision,
                    furniture,
                    upload,
                    `${process.env.REACT_APP_SERVICE}`,
                    style
                ).then(async (image) => {
                    const item = `${style}-${i + 1}`;

                    setItems((prevItems) => {
                        return {
                            ...prevItems,
                            [item]: {
                                ...prevItems[item],
                                image: image,
                                loading: false
                            }
                        };
                    });
                    setIsLoading(false);
                });
            }
        });
    };

    const { t } = useTranslation();

    useEffect(() => {
        setStyleCount(
            Object.values(styles[mode]).reduce(
                (acc, current) => acc + current.count,
                0
            )
        );
    }, [styles]);

    return (
        <Modal
            className="style-modal"
            contentLabel="Select Styles"
            isOpen={isOpen}
            onRequestClose={onClose}
        >
            <div className="header">
                <h1>
                    <div
                        className="money-box"
                        style={
                            genCount
                                ? {}
                                : {
                                      display: 'none'
                                  }
                        }
                    >
                        <span className="price">
                            ${parseFloat(genCount * 0.5).toFixed(2)}
                        </span>
                        <button
                            className="header-button"
                            onClick={setConfirmationModalOpen(true)}
                        >
                            <FaCartShopping />
                        </button>
                    </div>
                </h1>
                <>
                    {styleCount ? (
                        <button
                            className="pulse-button"
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            {t('generate')}
                        </button>
                    ) : (
                        <button
                            className="upload-button"
                            onClick={() => {
                                uploadRef.current.click();
                            }}
                        >
                            <FaCirclePlus className="upload-icon" />
                        </button>
                    )}
                </>
                <>
                    <button
                        className="header-button"
                        onClick={() => {
                            setExteriorMode(!exteriorMode);
                        }}
                        style={{
                            fill: exteriorMode ? '#e3df04' : 'white'
                        }}
                    >
                        <FaHouseChimney />
                    </button>
                </>
                <ConfirmationModal
                    cancelButtonText={t('cancel')}
                    confirmButtonText={t('confirm')}
                    content={t('payment.question')}
                    isOpen={confirmationModalOpen}
                    onCancel={setConfirmationModalOpen(false)}
                    onClose={setConfirmationModalOpen(false)}
                    onConfirm={handleConfirmCheckout}
                    title={t('payment.title')}
                />
            </div>
            <div className="style-select-gallery">
                <div className="checkbox-container">
                    {Object.keys(styles[mode]).map((item) => (
                        <StyleSelectItem
                            handleAddStyle={handleAddStyle}
                            handleModifierChange={handleModifierChange}
                            handleRemoveStyle={handleRemoveStyle}
                            item={item}
                            key={item}
                            mode={mode}
                            styles={styles}
                            t={t}
                        />
                    ))}
                </div>
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'absolute',
                        right: '0.25em',
                        top: '0.25em'
                    }}
                ></div>
            </div>
        </Modal>
    );
};

StyleSelectModal.propTypes = {
    ConfirmationModal: PropTypes.func.isRequired,
    confirmationModalOpen: PropTypes.bool.isRequired,
    genCount: PropTypes.number.isRequired,
    handleCheckoutResume: PropTypes.func.isRequired,
    handleConfirmCheckout: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    sessionID: PropTypes.string.isRequired,
    setConfirmationModalOpen: PropTypes.func.isRequired,
    setGenCount: PropTypes.func.isRequired,
    setIsLoading: PropTypes.bool.isRequired,
    setItems: PropTypes.func.isRequired,
    setStyles: PropTypes.func.isRequired,
    styles: PropTypes.objectOf(
        PropTypes.shape({
            count: PropTypes.number,
            furniture: PropTypes.oneOfType([PropTypes.string, PropTypes.null])
        })
    ).isRequired,
    upload: PropTypes.string.isRequired,
    uploadRef: PropTypes.func.isRequired
};

export default StyleSelectModal;
