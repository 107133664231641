import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import './StyleSelectItem.css';
import { FaAccusoft, FaChair, FaTree } from 'react-icons/fa6';

const StyleSelectItem = ({
    handleAddStyle,
    handleModifierChange,
    handleRemoveStyle,
    item,
    mode,
    styles,
    t
}) => {
    const [activeModifier, setActiveModifier] = useState(null);

    const config = useMemo(
        () => ({
            furniture: {
                icon: FaChair,
                modes: ['interior'],
                options: process.env.REACT_APP_FURNITURE.split(',')
            },
            landscape: {
                icon: FaTree,
                modes: ['exterior'],
                options: process.env.REACT_APP_LANDSCAPE.split(',')
            },
            roof: {
                icon: FaAccusoft,
                modes: ['exterior'],
                options: process.env.REACT_APP_ROOF.split(',')
            }
        }),
        []
    );

    const modifiers = useMemo(
        () =>
            Object.entries(config)
                .filter(([, configuration]) =>
                    configuration.modes.includes(mode)
                )
                .map(([key]) => key),
        [config, mode]
    );

    const modifierIcons = () => (
        <div className="modifier">
            {modifiers.map((modifierType) => (
                <button
                    key={modifierType}
                    className={`modifier-button ${
                        activeModifier === modifierType ? 'active' : ''
                    }`}
                    onClick={() =>
                        setActiveModifier(
                            activeModifier === modifierType
                                ? null
                                : modifierType
                        )
                    }
                    title={t(modifierType)}
                >
                    {config[modifierType].icon}
                </button>
            ))}
        </div>
    );

    const modifierDropdown = () => (
        <div className="modifier-dropdowns">
            {modifiers.map(
                (modifierType) =>
                    styles[mode][item]?.modifier?.[modifierType] !==
                        undefined && (
                        <div key={modifierType} className="modifier-dropdown">
                            <select
                                aria-label={t(modifierType)}
                                id={`${item}-${modifierType}`}
                                onChange={(event) =>
                                    handleModifierChange(
                                        modifierType,
                                        item,
                                        event.target.value
                                    )
                                }
                                value={
                                    styles[mode][item].modifier[modifierType] ||
                                    ''
                                }
                            >
                                <option value="" disabled>
                                    {t(modifierType)}
                                </option>
                                {config[modifierType].options.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )
            )}
        </div>
    );

    return (
        <div className="checkbox-container-item" key={item}>
            <input
                checked={false}
                className="checkbox"
                id={item}
                name={item}
                onClick={(event) => {
                    handleAddStyle(event.target.name);
                }}
                type="checkbox"
            />
            <label
                className="checkbox-label"
                htmlFor={item}
                name={item}
                style={{
                    backgroundImage: `url(${process.env.REACT_APP_THUMBNAILS}/${mode}/${encodeURIComponent(item)}.jpg)`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
            >
                {item.replace('_', ' ').toUpperCase()}
            </label>
            {styles[mode][item].count && (
                <div className="counter-dropdown">
                    <div className="counter">
                        <button
                            className="counter-button"
                            name={item}
                            onClick={(event) => {
                                handleRemoveStyle(event.target.name);
                            }}
                        >
                            -
                        </button>
                        <span className="counter-value">
                            {styles[mode][item].count}
                        </span>
                        <button
                            className="counter-button"
                            name={item}
                            onClick={(event) => {
                                handleAddStyle(event.target.name);
                            }}
                        >
                            +
                        </button>
                    </div>
                    {modifierIcons()}
                    {modifierDropdown()}
                </div>
            )}
        </div>
    );
};

StyleSelectItem.propTypes = {
    handleAddStyle: PropTypes.func,
    handleModifierChange: PropTypes.func,
    handleRemoveStyle: PropTypes.func,
    item: PropTypes.string,
    mode: PropTypes.string,
    styles: PropTypes.object,
    t: PropTypes.func
};

export default StyleSelectItem;
