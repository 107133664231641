import PropTypes from 'prop-types';
import React from 'react';
import './StyleSelectItem.css';

const StyleSelectItem = ({
    handleAddStyle,
    handleModifierChange,
    handleRemoveStyle,
    item,
    mode,
    styles,
    t
}) => {
    const furnitureModifiers = process.env.REACT_APP_FURNITURE.split(',');

    return (
        <div className="checkbox-container-item" key={item}>
            <input
                checked={false}
                className="checkbox"
                id={item}
                name={item}
                onClick={(event) => {
                    handleAddStyle(event.target.name);
                }}
                type="checkbox"
            />
            <label
                className="checkbox-label"
                htmlFor={item}
                name={item}
                style={{
                    backgroundImage: `url(${process.env.REACT_APP_THUMBNAILS}/${mode}/${encodeURIComponent(item)}.jpg)`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
            >
                {item.replace('_', ' ').toUpperCase()}
            </label>
            {styles[mode][item].count && (
                <div className="counter-dropdown">
                    <div className="counter">
                        <button
                            className="counter-button"
                            name={item}
                            onClick={(event) => {
                                handleRemoveStyle(event.target.name);
                            }}
                        >
                            -
                        </button>
                        <span className="counter-value">
                            {styles[mode][item].count}
                        </span>
                        <button
                            className="counter-button"
                            name={item}
                            onClick={(event) => {
                                handleAddStyle(event.target.name);
                            }}
                        >
                            +
                        </button>
                    </div>
                    <div className="modifier-dropdown">
                        <select
                            aria-placeholder={t('furniture')}
                            id={`${item}-furniture`}
                            key={item}
                            onChange={(event) =>
                                handleModifierChange(
                                    'furniture',
                                    item,
                                    event.target.value
                                )
                            }
                            value={styles[mode][item]['modifier'].furniture}
                        >
                            <option value="" disabled selected>
                                {t('furniture')}
                            </option>
                            {furnitureModifiers.map((type) => (
                                <option key={type} value={type}>
                                    {type}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            )}
        </div>
    );
};

StyleSelectItem.propTypes = {
    handleAddStyle: PropTypes.func,
    handleModifierChange: PropTypes.func,
    handleRemoveStyle: PropTypes.func,
    item: PropTypes.string,
    mode: PropTypes.string,
    styles: PropTypes.object,
    t: PropTypes.func
};

export default StyleSelectItem;
